

__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import {onLoading} from "./_modules/website";
import {loadPage} from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import Swup from 'swup';
import {Collapsible, CloseMenu, SetLang} from "./_modules/collapsible";


import fullpage from 'fullpage.js';
import 'fullpage.js/dist/fullpage.css';
import 'flickity/css/flickity.css';
import 'flickity-fade';


var Flickity = require('flickity');


document.addEventListener("DOMContentLoaded", () => {

    const lang = document.documentElement.getAttribute('lang');
    SetLang(lang);

    const fullpageCont = document.querySelector('#fullpage');

    if (fullpageCont) {
        var fullPageInstance = new fullpage('#fullpage', {
            licenseKey: 'gplv3-license',
            scrollingSpeed: 400,
            scrollBar: false,
            scrollOverflow: true,
            beforeLeave: function (origin, destination, direction, trigger) {
                const activePageLink = document.querySelector('.main-menu li:first-of-type a');

                /*if (destination.index == 0) {
                    return false;
                }*/

                activePageLink.classList.add('active');
            }
        });
    }
    const Init = () => {

        const videos = document.querySelectorAll('video');
        videos.forEach((elem) =>{
            elem.play().catch(error => {
                console.log(`Error playing video: ${error}`);
            });
        });


        const activePage = document.body.getAttribute("id");

        if(fullPageInstance){
            const secondSection = document.querySelector("#fullpage .section:last-of-type");
            secondSection.querySelector('.fp-overflow').scrollTo(0,1);
        }

        if(fullPageInstance && activePage !== 'home'){
            fullpage_api.silentMoveTo(2);
        }


        Collapsible();

        const menuLinks = document.querySelectorAll('.main-menu .main-menu-link');

        menuLinks.forEach((elem) => {
                elem.addEventListener('click', () => {
                    menuLinks.forEach((elem) =>{
                       elem.classList.remove('active');
                    });
                    elem.classList.add('active');
                });
        });

        const langMenuLinks = document.querySelectorAll('.main-menu .lang-menu li a');

        langMenuLinks.forEach((elem) => {
            elem.addEventListener('click', () => {
                langMenuLinks.forEach((elem) =>{
                    elem.classList.remove('active');
                });
                elem.classList.add('active');
                const lang = elem.getAttribute("hreflang");
                SetLang(lang);
            });
        });

        const slideshowCont = document.querySelector('.carousel');

        if (slideshowCont) {
            var flkty = new Flickity('.carousel', {
                lazyLoad: 2,
                initialIndex: Math.floor(Math.random() * slideshowCont.children.length),
                autoPlay: 2500,
                fade: true,
                groupCells: true,
                wrapAround: true,
                selectedAttraction: 0.2,
                pauseAutoPlayOnHover: false,
                friction: 1,
                pageDots: false,
                prevNextButtons: true,
            });
        }

        const subMenuLinks = document.querySelectorAll('.sub-menu a');
        const commandLists = document.querySelectorAll('.command-list');

        subMenuLinks.forEach((elem) => {

            elem.addEventListener('click', function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                commandLists.forEach((elem) => {
                    elem.classList.remove('show');
                });
                subMenuLinks.forEach((elem) => {
                    elem.classList.remove('active');
                });
                elem.classList.add('active');
                const targetId = elem.dataset.target;
                console.log(targetId);
                const target = document.querySelector(targetId);

                target.classList.add('show');

            });
        });
    }

    onLoading();
    loadPage();
    Init();

    const swup = new Swup({
        containers: ["#swup"],
        linkSelector: 'a:not([data-no-swup])',
    });

    swup.hooks.on('content:replace', () => {
        const secondSection = document.querySelector("#fullpage .section:last-of-type");
        var id = (swup.location.pathname !== "/") ? swup.location.pathname.split("/").filter(Boolean).pop() : 'home';
        document.body.setAttribute("id", id);
        console.log(fullpage_api.getActiveSection());

        if(secondSection == fullpage_api.getActiveSection().item){
           setTimeout(function (){
               fullpage_api.moveTo(2);
           }, 1)
        }
        else if(fullpageCont) {
            fullpage_api.moveTo(2);
        }
    }, {before: true});

    swup.hooks.on('page:view', () => {

        const langMenuLinks = document.querySelectorAll('.main-menu .lang-menu li a');

        langMenuLinks.forEach((elem) => {
            const pageId = document.body.getAttribute('id');
            const href = elem.getAttribute ('href');
            const newHref = href.replace(/[^/]+$/, pageId);
            elem.setAttribute('href', newHref);
        });


        CloseMenu();
        onLoading();
        loadPage();
        Init();
    });

});